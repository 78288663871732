import React from 'react'
import './About.css'
import banner from '../images/aboutUsBanner.png'
import fillerImage from '../images/blank-pfp.png'

function About() {
  return (
    <>
        <div className='aboutBody'>
            <center>
            <div className='aboutContainer'>
                <div className='blurb'>
                    <h1>About Us</h1>
                    <h4>
                        Hi there! <br /><br />

                        We are a group of high schoolers from the San Francisco Bay Area with an idea to help our community. 
                        Have you ever been walking outside and noticed trees with hundreds of unpicked fruits falling 
                        to the ground? We have, and we’ve realized that many homeowners have more fruits than they 
                        can use. <br /><br />

                        Our mission is to reduce this wastage by providing homeowners with the resources they need to 
                        put their surplus fruits to good use. If you have extra fruits and want to help your community, 
                        register to get your fruits picked and make a difference today. <br /><br />

                        Thanks! <br /><br />

                        <h4 className='signature'>Aditya Jha and Arav Bansal</h4>

                    </h4>
                </div>
                <div className='imgContainer'>
                    <img src={banner} alt='About banner'></img>
                </div>
            </div>
            <center><h2>Meet Our Team</h2></center>
            </center>

            <center>
            <div className='profiles'>
                <div className='person'>
                    <img alt = "" src={fillerImage}></img>
                    <h3>Aditya Jha</h3>
                    <h4 className='title'>Co-Founder</h4>
                    <h4 className='school'>Junior at Leigh High School</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={fillerImage}></img>
                    <h3>Arav Bansal</h3>
                    <h4 className='title'>Co-Founder</h4>
                    <h4 className='school'>Junior at Saint Francis High</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={fillerImage}></img>
                    <h3>Aryan Mohanty</h3>
                    <h4 className='title'>Outreach Lead</h4>
                    <h4 className='school'>Sophomore at Leigh High School</h4>
                </div>
                <div className='person'>
                    <img alt = "" src={fillerImage}></img>
                    <h3>Aishani Bansal</h3>
                    <h4 className='title'>Creative Director</h4>
                    <h4 className='school'>Freshman at Saint Francis High</h4>
                </div>
                
            </div>
            </center>
        </div>
    </>
  )
}

export default About